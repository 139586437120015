@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body,
.text-black,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #135D66;
}

.dark\:bg-black:is(.dark *) {
  background-color: #135D66 !important;
}

.dark\:text-white:is(.dark *) {
  color: #FEFBF6 !important;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: #135D66;
}

@layer utilities {
  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

.toastBody {
  font-family: 'Roboto', sans-serif;
  color: #000;
}

.toastProgress {
  background: #000 !important;
}

.toastBodyDark {
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.toastProgressDark {
  background: #fff !important;
}

.textarea-container {
  position: relative;
}

.textarea {
  resize: none;
  overflow-y: hidden;
}

.textarea::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.home-holder {
  background-image: url('/src/res/img/falling-emoji.gif');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ai ol,
.ai ul,
.ai menu {
  list-style: disc;
  margin: 0;
  padding: 0;
  padding-left: 36px;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  border-color: #135D66;
  --tw-ring-color: #135D66;
}